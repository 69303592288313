
*{
  margin: 2px;
}
body {
  background-color: white;

}
/*table*/


.content-table {
  border-collapse: collapse;
  border: 1px solid #c4bfbf;
  width: 98%;
  min-width: 400px;
  margin-bottom: 25px ;
  margin-left: 1%;
}

.content-table thead th {

  text-align: left;
  border: 1px solid #000000;
  padding: 10px 10px;
}

.content-table tbody tr td {
  text-align: left;
  border: 1px solid #dbd8d8;
  padding: 8px 8px;
}

.content-table tbody tr:nth-of-type(odd){
  background-color: #F0F0F0;
}


.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #dbd8d8;
}

/* Button */

.btn { background-color: white;
  color: black;
  border: 1px solid #1e9024;
  padding: 4px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  margin: 4px 2px;
  transition-duration: 0.3s;
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  min-height: 28px;
  padding-top: 8px;
}
.span-table {
  font-size: 14px;
  display: block;
  @media (max-width: 1075px) {
    font-size: 12px;
  }
  @media (max-width: 600px) {
    font-size: 10px;
  }
}
.btn:hover {
  background-color: #1e9024;
  color: white;
}


/*  SPAN PAGES   */

.span-page {
  margin-top: 10px;
  width: 98%;
  margin-left: 1.5%;

}

.table-wrapper {
  box-shadow: 0 35px 50px rgba(0, 0, 0, 0.2);
  width: 97%;
  margin-left: 1.5%;

  body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.25;
  }

  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  }

  table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: .35em;
  }

  table th,
  table td {
    padding: .625em;
    text-align: center;
  }

  table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
  }

  @media screen and (max-width: 920px) {
    table {
      border: 0;
    }

    table caption {
      font-size: 1.3em;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }

    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
      @media (max-width: 750px) {
        font-size: .7em;
      }
      @media (max-width: 500px) {
        font-size: .6em;
      }
    }

    table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
}
.export-button {
  margin-left: 1.5%;
  padding-bottom: 10px;
}
.table-wrapper-purchase{
  box-shadow: 0 35px 50px rgba(0, 0, 0, 0.2);
  width: 97%;
  margin-left: 1.5%;

  body {
    font-family: "Open Sans", sans-serif;
    line-height: 1.25;
  }

  table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
  }

  table caption {
    font-size: 1.5em;
    margin: .5em 0 .75em;
  }

  table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: .35em;
  }

  table th,
  table td {
    padding: .625em;
    text-align: center;
  }

  table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
  }

  @media screen and (max-width: 2800px) {
    table {
      border: 0;
    }

    table caption {
      font-size: 1.3em;
    }

    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }

    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }

    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
      @media (max-width: 750px) {
        font-size: .7em;
      }
      @media (max-width: 500px) {
        font-size: .6em;
      }
    }

    table td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }

    table td:last-child {
      border-bottom: 0;
    }
  }
}