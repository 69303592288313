
// VARIABLES

$white: #ffffff;
$black: #000000;
$lightBlue: #0a2e49;
$deepgreen: #0baa45;
$red: #db3535;
$activ: #cbc6c6;

