
*{
  margin: 20px;
  padding: 20px;
}
body {
 background-color: white;

}

/*header*/
.active-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 10px;
  @media (max-width: 700px) {
    display: block;
  }
}
.add {
  justify-content: space-around;
}
.active-header-button {
  @media (max-width: 700px) {
  text-align: center;
    padding-top: 10px;
  }
  @media (max-width: 400px) {
    padding-top: 5px;
    text-align: center;
  }
}
.active-block {
  display: block;
  align-items: center;
  justify-content: space-between;
  p{
    //text-align: center;
  }
}
.add-partner-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 10px;
  @media (max-width: 860px) {
    display: block;
  }
}
.btn {
  font-size: 12px;
  padding: 4px 6px;
  border: 1px solid black;
  cursor: pointer;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  color: black;justify-content: center;
}

.btn:hover {
  background-color: #D5D5D5;
}