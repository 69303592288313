

.add-admin {
  max-width: 640px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.add-admin h1 {
  font-size: 23px;
  padding: 10px ;
  text-align: left;
}

.add-admin-inside {
  padding: 30px 50px;
  background-color: #F0F0F0;
  border-radius: 4px;
  box-shadow: 15px 15px 25px 0 rgba(#32324B, 0.4);
}

//.input {
//  background-color: orange;
//  width: 91%;
//  position: relative;
//  display: flex;
//  padding: 10px ;
//  margin: 5px 0 ;
//  border: 1px solid #8b8b8b;
//  border-radius: 2px;
//  font-size: 10px;
//}


label {
  margin-left: 15px ;
}


//.option-choose {
//  width: 98%;
//  padding: 10px;
//  margin: 10px 0  ;
//  border-radius: 2px;
//  border: 1px solid black;
//  margin-left: 5px ;
//}



/* BUTTON */

.btn-center {
  text-align: center;
}

//
//.btn-add {
//  font-size: 12px;
//  padding: 10px;
//  cursor: pointer;
//  border-radius: 2px;
//  border: 1px solid black;
//  width: 100%;
//}

/* ИТОГ КНОПКИ */






/*     MEDIA    */


//
//@media (max-width: 1050px) {
//  .add-admin,
//  .add-admin-inside,
//  .input{
//    align-items: center;
//    justify-content: center;
//    float: none;
//  }
//  .add-admin-inside {
//    margin-top: 15px;
//  }
//  .input {
//    width: 95%;
//  }
//}







