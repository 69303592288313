.categories_bar {
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding: 10px 10px;
  margin: 20px 20px;
  @media (max-width: 700px) {
    padding: 5px 5px;
    margin: 10px 10px;
  }
  @media (max-width: 400px) {
    padding: 2px 2px;
    margin: 5px 5px;
  }
}
h2 {
  @media (max-width: 700px) {
font-size: 20px;
  }
  @media (max-width: 400px) {
    font-size: 14px;
  }
}
.structure-fon {
  background-color: #b42c8e;
  height: auto;
  border: 1px solid #b42c8e;
  border-radius: 7px;
  padding: 10px 10px;
  margin: 30px 20px;
  @media (max-width: 700px) {
    padding: 5px 5px;
    margin: 10px 10px;
  }
  @media (max-width: 400px) {
    padding: 2px 2px;
    margin: 5px 5px;
  }
}
