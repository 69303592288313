@import '../../../Styles/Variables/Colors.scss';


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.font {
  font-size: 48px;

}
.span-balance {
  display: flex;
  @media (max-width: 615px) {
    display: block;
  }
}
.span-button {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  @media (max-width: 1075px) {
    display: block;
    font-size: 10px;
  }
  @media (max-width: 600px) {
    display: block;
    font-size: 9px;
  }
}
.navbar {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #000000;
  padding: 5px 25px;
  background-color: $lightBlue;
  align-items: center;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
}

.navbar_logo {
    font-size: 20px;
    padding: 4px 10px;
  }


.navbar_logo:hover{
  background-color: white;
  padding: 4px 10px;
  border-radius: 4px;
  transition-duration: 0.3s;
  color: $deepgreen;
  }



.drop_link {
  font-size: 13px;
  margin: 0 20px;
  padding: 4px 10px;
  color: #000000;
  text-decoration: none;
  display: inline-block;
}
.is_active {
  background-color: #cecece;
  padding: 4px 10px;
  border-radius: 4px;
  transition-duration: 0.3s;
  color: #2f2f2f;
}

.drop_link:hover {
  background-color: white;
  padding: 4px 10px;
  border-radius: 4px;
  transition-duration: 0.3s;
  color: #0A2E49;
  }

  .nav_button {
    font-size: 13px;
    margin: 0 20px;
    padding: 4px 10px;
    color: $lightBlue;
    text-decoration: none;
    display: inline-block;
  }

.nav_link {
  font-size: 13px;
  margin: 0 20px;
  padding: 4px 10px;
  color: $white;
  text-decoration: none;
  display: inline-block;
}
.is-active {
  background-color: $white;
  padding: 4px 10px;
  border-radius: 4px;
  transition-duration: 0.3s;
  color: $lightBlue;
}

.nav_link:hover {
  background-color: $white;
  padding: 4px 10px;
  border-radius: 4px;
  transition-duration: 0.3s;
  color: $lightBlue;
}

.nav_button {
  font-size: 13px;
  margin: 0 20px;
  padding: 4px 10px;
  color: $lightBlue;
  text-decoration: none;
  display: inline-block;
}

@media (max-width: 800px) {
  .navbar ,
  .navbar .nav ,
  .navbar nav ,
  .nav_button {
    display: grid;
    text-align: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
  }

  .navbar .navbar_logo {
    font-size: 16px;
    padding:  4px ;
    @media (max-width: 500px) {
      font-size: 10px;
    }
  }


  .navbar_logo:hover {
    background-color: white;
    padding: 4px 4px;
    border-radius: 4px;
  }

  .nav_button {
    font-size: 14px;
    padding: 10px;
  }

  .navbar .nav .nav_link {
    font-size: 14px;
    padding: 10px;
    @media (max-width: 500px) {
      font-size: 10px;
      padding: 10px;
    }
  }
}
.web-media {
  display: inline-flex;
  @media (max-width: 800px) {
    display: none;
  }
}
.phone-media {
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
}