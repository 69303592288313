.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  .center {
    margin: auto;
  }
}
.loader-container-minimize {
  width: 100%;
  height: 40vh;
  display: flex;
  .center {
    margin: auto;
  }
}
body {
  margin: 10px 10px;
}
label {
  font-size: 14px;
}
.success-message {
  font-size: 14px;
  color: #056c05;
}
.error-message {
  font-size: 14px;
  color: red;
}
.partner-page {
  align-content: center;
  align-items: center;
  margin: auto auto;
  button {
    margin-left: 2.8%;
  }
}
.modal-page {
  align-content: center;
  align-items: center;
  margin: auto auto;
  max-height: 410px;
  overflow-y: auto;
  button {
    margin-left: 2.8%;
  }
}
.purchase-input {
  height: 25px;
  width: 50px;
  border-radius: 3px;
  @media (max-width: 700px) {
    height: 20px;
  }
}
.react-select-position {
  margin-left: 2.5%;
}
footer {
  color: #0b204c;
  padding-top: 30px;
  flex: 0 0 auto;
  text-align: center;
  @media (max-width: 700px) {
    font-size: 14px;
  }
  @media (max-width: 500px) {
  font-size: 11px;
  }
}
.block {
  display: block;
  justify-content: space-between;
}
.tree {
  font-size: 20px;
  cursor: pointer;
  display: block;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  align-content: center;
  padding: 0;
  @media (max-width: 700px) {
    font-size: 15px;
  }
  @media (max-width: 500px) {
    font-size: 10px;
  }
  .second {
    display: flex;
    justify-content: space-around;
    margin-top: 70px;
  }
  .third {
    display: flex;
    justify-content: space-around;
    margin-top: 70px;
  }
}
.first {
  display: flex;
  justify-content: space-around;
}
.index {
  z-index: 999;
}
.not-index {
  z-index: unset;
}
.half {
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin-left: 25%;
  margin-top: 30px;
  margin-bottom: 30px;
  @media (max-width: 700px) {
    width: 90%;
    margin-left: 4%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.tree-span {
  margin-left: 1%;
  font-size: 30px;
  color: #0baa45;
}
.modal {
  width: 50%;
  height: auto;
  position: fixed;
  top: 10%;
  left: 38%;
  margin-left: -12%;
  background-color: #fff;
  border-radius: 10px;
  z-index: 100;
  padding: 15px;
  outline: none;
  box-shadow: 0 0 4px rgba(0,0,0,.14),0 4px 8px rgba(0,0,0,.28);
  @media (max-width: 1044px) {
    width: 70%;
    left: 28%;
  }
  @media (max-width: 880px) {
    width: 80%;
    left: 23%;
  }
}
.purchase {
  height: 400px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
}
.exit-span {
  cursor: pointer;
  font-size: 20px;
  padding-left: 97%;
}
.filter-block {
  align-content: center;
  align-items: center;
  display: flex;
  width: 75%;
  height: auto;
  @media (max-width: 900px) {
    display: block;
    margin-top: 10px;
    width: 100%;
    margin-left: 2%;
  }
  @media (max-width: 500px) {
    margin-left: 0;
  }
}
.main {
  width: 100%;
  @media (max-width: 900px) {
    margin-left: 5%;
  }
  @media (max-width: 500px) {
    margin-left: 2.5%;
  }
}
.filter-select {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 900px) {
    width: 92%;
  }
  @media (max-width: 500px) {
    display: block;
    width: 100%;
  }
}
.filter-select-height {
  width: 33%;
  @media (max-width: 500px) {
    width: 100%;
  }
}
.filter-input {
  width: 33%;
  @media (max-width: 900px) {
    margin-top: 8px;
    width: 90%;
    margin-left: 3%;
  }
}
.filter-button {
  margin-top: 27px;
  @media (max-width: 900px) {
    margin-left: 39%;
  }
  @media (max-width: 500px) {
    margin-left: 37.5%;
  }
  @media (max-width: 420px) {
    margin-left: 35%;
  }
}
.main-button {
  @media (max-width: 900px) {
    margin-left: 39%;
  }
  @media (max-width: 700px) {
    margin-left: 33%;
  }
  @media (max-width: 420px) {
    margin-left: 29%;
  }
  @media (max-width: 350px) {
    margin-left: 25%;
  }
}
.searchBlock {
  width: 25%;
  @media (max-width: 900px) {
    width: 100%;
    display: block;
  }
}
.search-partner-block {
  margin-left: 30%;
}
.filter-header {
  margin-left: 1%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: 900px) {
    display: block;
  }
}
.block-bottom-padding {
  color: red;
}
.datepicker-height {
  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    width: 97.5%;
    height: 25px;
  }
}
.structure-table {
  border-radius: 5px;
  font-size: 14px;
  font-weight: normal;
  border: none;
  border-collapse: collapse;
  width: 50%;
  max-width: 50%;
  white-space: nowrap;
  background-color: white;
  @media (max-width: 1044px) {
    width: 30%;
    max-width: 30%;
    font-size: 11px;
  }
  @media (max-width: 900px) {
    width: 25%;
    max-width: 25%;
    font-size: 9px;
  }
  @media (max-width: 850px) {
    width: 25%;
    max-width: 25%;
    font-size: 9px;
  }
  @media (max-width: 780px) {
    width: 20%;
    max-width: 20%;
    font-size: 8px;
  }
  @media (max-width: 850px) {
    display: none;
  }
}
.structure-table td, .structure-table th {
  text-align: center;
  padding: 8px;
}

.structure-table td {
  border-right: 1px solid #f8f8f8;
  font-size: 14px;
  @media (max-width: 1044px) {
    font-size: 11px;
  }
  @media (max-width: 900px) {
    font-size: 10px;
  }
  @media (max-width: 850px) {
    font-size: 9px;
  }
  @media (max-width: 780px) {
    font-size: 8px;
  }
}

.structure-table thead th {
  color: #ffffff;
  background: #092445;
}


.structure-table thead th:nth-child(odd) {
  color: #ffffff;
  background: #092445;
}

.structure-table tr:nth-child(even) {
  background: #F8F8F8;
}
.widget {
  font-size: 50px;
  line-height: 1;
  margin: 15px 0 25px;
  width: 300px;
  padding-bottom: 40px;
  padding-left: 25px;
  @media (max-width: 700px) {
    font-size: 40px;
    margin-left: 28%;
  }
  @media (max-width: 500px) {
    font-size: 30px;
    margin-left: 25%;
  }
  @media (max-width: 360px) {
    margin-left: 10%;
  }
  span{
    font-size: 22px;
    @media (max-width: 700px) {
      font-size: 12px;
    }
  }
  p {
    font-size: 18px;
    @media (max-width: 700px) {
      font-size: 10px;
    }
  }
}
.nav-list{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hidden{
  display: none;
}
.show {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: block;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
  box-shadow: 0 6px 12px rgba(0,0,0,.175);
}
.cursor {
  cursor: pointer;
}
.set {
  margin-top: 25px;
}
.main-info {
  margin-top: 50px;
  margin-bottom: 100px;
}

  .inactive thead th {
    color: #ffffff;
    background: #a10a0a;
  }


  .inactive thead th:nth-child(odd) {
    color: #ffffff;
    background: #a10a0a;
  }
.structure-button {
  margin-left: 1.5%;
}
.total-info {
  width: 90%;
  display: flex;
  margin-left: 5%;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 18px;
  font-family: Roboto;
  span {
    margin-top: 7px;
  }
}
.structure-text {
  border-radius: 4px;
  background-color: #092445;
  color: whitesmoke;
  display: none;
  @media (max-width: 850px) {
    z-index: 1;
    display: block;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
  }
  @media (max-width: 530px) {
    z-index: 1;
    display: block;
    font-size: 10px;
  }
  @media (max-width: 415px) {
    z-index: 1;
    display: block;
    font-size: 6.8px;
  }
}
.red-text {
  @media (max-width: 850px) {
    background-color: #a10a0a;
  }
}
.structure-info {
  display: none;
  @media (max-width: 850px) {
    z-index: 1;
    display: block;
    margin-top: 13%;
    margin-right: 1%;
  }
}
.tooltip {
  z-index: 1010;
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}
.tooltip .tooltiptext {
  width: auto;
  height: auto;
  background-color: #727272;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0 0;
  position: absolute;
  z-index: 1010;
  bottom: -260px;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  @media (max-width: 800px) {
    bottom: -275px;
    margin-left: -75px;
  }
  @media (max-width: 780px) {
    bottom: -270px;
    margin-left: -75px;
  }
  @media (max-width: 700px) {
    bottom: -190px;
    margin-left: -60px;
  }
  @media (max-width: 500px) {
    bottom: -130px;
    margin-left: -30px;
  }
  @media (max-width: 340px) {
    bottom: -135px;
  }
}
  .tooltip-visible .tooltiptext  {
    visibility: visible;
    opacity: 1;
}
  .require {
    color: red;
  }

  .table-empty {
    display: none;
    @media (max-width: 920px) {
      display: block;
      color: whitesmoke;
    }
  }
  .none {
    display: none;
  }
.inactive-partnership {
  color: red;
  text-align: center;
  margin-top: 10px;
  @media (max-width: 700px) {
    font-size: 12px;
  }
  @media (max-width: 350px) {
    font-size: 10px;
  }
}
.structure-search-block {
  margin-left: 5%;
  display: flex;
  margin-bottom: 30px;
  .search-block {
    width: 40%;
  }
  .week-block {
    width: 20%;
  }
  .button-block {
    margin-top: 27.5px;
  }
  @media (max-width: 970px) {
    display: block;
    margin-left: 30%;
    .week-block {
      width: 70%;
    }
    .search-block {
      width: 70%;
    }
    .button-block {
      margin-left: 25%;
    }
  }
  @media (max-width: 700px) {
    width: 100%;
    margin-left: 15%;
  }
  @media (max-width: 550px) {
    margin-left: 7.5%;
    margin-bottom: 10px;
    .search-block {
      width: 90%;
    }
    .week-block {
      width: 90%;
    }
    .button-block {
      margin-left: 32.5%;
    }
  }
}
.tooltip-zIndex {
  z-index: 1010;
  thead {
    z-index: 1010;
  }
  tbody {
    z-index: 1010;
  }
  th{
    z-index: 1010;
  }
  td {
    z-index: 1010;
  }
  p{
    z-index: 1010;
  }
  span {
    z-index: 1010;
  }
}
.react-select--editable .react-select__input input {
  opacity: 1 !important;
}
.partner-info {
  margin-top: 10px;
}
.purchase-height {
  max-height: 370px;
}