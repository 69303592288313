.footer {
  margin-top: 75px;
  padding-bottom: 40px;
}
.items-center {
  margin-top: 30px;
  display: flex;
  padding-right: 15%;
  padding-left: 15%;
  justify-content: space-between;
  @media (max-width: 700px) {
    display: block;
    padding-left: 0;
    margin-left: 6%;
  }
  @media (max-width: 700px) {
    margin-left: 3%;
  }
}
.new-table {
  font-family: 'lato', sans-serif;
  max-width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

h2 {
  font-family: 'lato', sans-serif;
  font-size: 26px;
  margin: 20px 0;
  text-align: center;
  @media (max-width: 767px) {
    text-align: start;
    margin-left: 10%;
  }
  @media (max-width: 500px) {
  font-size: 15px;
  }
  small {
    font-size: 0.5em;
  }
}

.responsive-table {
  li {
    border-radius: 3px;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  .table-header {
    background-color: #a09695;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
  .table-row {
    background-color: #efeae7;
    box-shadow: 0 0 9px 0 rgba(0,0,0,0.1);
  }
  .col-1 {
    flex-basis: 10%;
  }
  .col-2 {
    flex-basis: 25%;
  }
  .col-3 {
    flex-basis: 25%;
  }
  .col-4 {
    flex-basis: 25%;
  }

  @media all and (max-width: 1950px) {
    p {
      padding-left: 4px;
    }
    span {
      padding-left: 4px;
    }
    .table-header {
      display: none;
    }
    .table-row{

    }
    li {
      display: block;
    }
    .col {

      flex-basis: 100%;

    }
    .col {
      display: flex;
      padding: 10px 0;
      font-size: 15px;
      &:before {
        color: #6C7A89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 50%;
        text-align: right;
      }
    }
  }
  @media all and (max-width: 680px) {
    p {
      padding-left: 4px;
    }
    span {
      padding-left: 4px;
    }
    .table-header {
      display: none;
    }
    .table-row{

    }
    li {
      display: block;
    }
    .col {

      flex-basis: 100%;

    }
    .col {
      display: flex;
      padding: 10px 0;
      &:before {
        color: #6C7A89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 10%;
        text-align: right;
      }
    }
  }
  @media all and (max-width: 500px) {
    .col {
      display: flex;
      padding: 10px 0;
      font-size: 10px;
      &:before {
        color: #6C7A89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 10%;
        text-align: right;
      }
    }
  }
}
.dropdown {
  float: left;
  overflow: hidden;
}

/* Dropdown button */
.dropdown .dropbtn {
  font-size: 13px;;
  border: none;
  border-radius: 4px;
  outline: none;
  color: #ffffff;
  background-color: inherit;
  font-family: inherit; /* Important for vertical align on mobile phones */
  //margin: 0; /* Important for vertical align on mobile phones */
}

/* Add a red background color to navbar links on hover */
.navbar a:hover, .dropdown:hover .dropbtn {
  background-color: white;
  color: #0a2e49;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  border-radius: 5px;
  background-color: #fafafa;
  min-width: 120px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  float: none;
  //color: black;
  padding: 4px 6px;
  margin-bottom: 4px;
  text-decoration: none;
  display: block;
  text-align: left;
}

/* Add a grey background color to dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}
.nav-span {
  font-size: 14px;
}
.new-table-info {
  font-family: 'lato', sans-serif;
  max-width: 95%;
  //margin-left: auto;
  //margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}
.responsive-table-info {
  li {
    border-radius: 3px;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
  }
  .table-header {
    background-color: #a09695;
    font-size: 10px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
  .table-row {
    background-color: #efeae7;
    box-shadow: 0 0 9px 0 rgba(0,0,0,0.1);
  }
  .col-1 {
    flex-basis: 10%;
  }
  .col-2 {
    flex-basis: 25%;
  }
  .col-3 {
    flex-basis: 25%;
  }
  .col-4 {
    flex-basis: 25%;
  }
  @media all and (max-width: 2800px) {
    p {
      padding-left: 4px;
    }
    span {
      padding-left: 4px;
    }
    .table-header {
      display: none;
    }
    .table-row{

    }
    li {
      display: block;
    }
    .col {

      flex-basis: 100%;

    }
    .col {
      display: flex;
      padding: 3px 0;
      font-size: 14px;
      &:before {
        color: #6C7A89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 40%;
        text-align: right;
      }
    }
  }
  @media all and (max-width: 1950px) {
    p {
      padding-left: 4px;
    }
    span {
      padding-left: 4px;
    }
    .table-header {
      display: none;
    }
    .table-row{

    }
    li {
      display: block;
    }
    .col {

      flex-basis: 100%;

    }
    .col {
      display: flex;
      padding: 3px 0;
      font-size: 14px;
      &:before {
        color: #6C7A89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 40%;
        text-align: right;
      }
    }
  }
  @media all and (max-width: 1300px) {
    p {
      padding-left: 4px;
    }
    span {
      padding-left: 4px;
    }
    .table-header {
      display: none;
    }
    .table-row{

    }
    li {
      display: block;
    }
    .col {

      flex-basis: 100%;

    }
    .col {
      display: flex;
      padding: 2px 0;
      font-size: 12px;
      &:before {
        color: #6C7A89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 40%;
        text-align: right;
      }
    }
  }
  @media all and (max-width: 770px) {
    p {
      padding-left: 4px;
    }
    span {
      padding-left: 4px;
    }
    .table-header {
      display: none;
    }
    .table-row{

    }
    li {
      display: block;
    }
    .col {
      flex-basis: 100%;

    }
    .col {
      display: flex;
      padding: 1px 0;
      font-size: 12px;
      &:before {
        color: #6C7A89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 20%;
        text-align: right;
      }
    }
  }
  @media all and (max-width: 670px) {
    p {
      padding-left: 4px;
    }
    span {
      padding-left: 4px;
    }
    .table-header {
      display: none;
    }
    .table-row{

    }
    li {
      display: block;
    }
    .col {
      flex-basis: 100%;

    }
    .col {
      display: flex;
      padding: 0.5px 0;
      font-size: 10px;
      &:before {
        color: #6C7A89;
        padding-right: 10px;
        content: attr(data-label);
        flex-basis: 30%;
        text-align: right;
      }
    }
  }
  @media all and (max-width: 500px) {
    .col {
      display: flex;
      padding: 1px 0;
      font-size: 9px;
      &:before {
        color: #6C7A89;
        padding-right: 5px;
        content: attr(data-label);
        flex-basis: 25%;
        text-align: right;
      }
    }
  }
  @media all and (max-width: 470px) {
    .col {
      display: flex;
      padding: 1px 0;
      font-size: 9px;
      &:before {
        color: #6C7A89;
        padding-right: 5px;
        content: attr(data-label);
        flex-basis: 30%;
        text-align: right;
      }
    }
  }
  @media all and (max-width: 400px) {
    .col {
      flex-basis: 100%;
      display: block;
    }
    .col {
      display: block;
      padding: 1px 0;
      font-size: 9px;
      &:before {
        color: #6C7A89;
        padding-right: 5px;
        content: attr(data-label);
        flex-basis: 1%;
        //text-align: right;
      }
    }
  }
}