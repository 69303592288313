.search {
  align-items: center;
  display: flex;
  width: 30%;
  font-size: 18px;
  label {
    margin-left: 15px;
    @media (max-width: 700px) {
      font-size: 14px;
    }
    @media (max-width: 500px) {
      font-size: 11px;
    }
    @media (max-width: 400px) {
      font-size: 10px;
    }
  }
  input {
    margin-left: 10px;
    outline: none;
    width: 100%;
    height: 25px;
    border: none;
    border-bottom: 2px solid gray;
    font-size: 12px;
    @media (max-width: 700px) {
      font-size: 10px;
    }
    @media (max-width: 500px) {
      font-size: 9px;
    }
    @media (max-width: 400px) {
      font-size: 8px;
    }
  }
  input:focus {
    border-bottom: 2px solid #3c3ca8;
  }
  @media (max-width: 700px) {
    width: 90%;
    height: 25px;
    padding-bottom: 10px;
    font-size: 10px;
  }
  @media (max-width: 400px) {
    width: 90%;
    height: 25px;
    padding-bottom: 10px;
    font-size: 8px;
  }
}
.basic {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  display: block;
  justify-content: space-between;
  label {
    margin-left: 15px;
    @media (max-width: 700px) {
      margin-left: 10px;
    }
    @media (max-width: 400px) {
      margin-left: 5px;
    }
  }
  input {
    height: 38px;
    margin-top: 10px;
    background-color: white;
    width: 95%;
    position: relative;
    box-sizing: border-box;
    padding: 10px ;
    border: 1px solid #8b8b8b;
    border-radius: 2px;
    font-size: 12px;
    @media (max-width: 700px) {
      font-size: 10px;
    }
    @media (max-width: 400px) {
      font-size: 9px;
    }
  }
  input:focus {
    border: none;
    border-bottom: 2px solid #3131a1;
    outline: none;
    label {
      border: 1px solid red;
    }
  }
  .validation-message {
    display: block;
    font-size: 14px;
    color: red;
    padding-top: 4px;
    @media (max-width: 700px) {
      font-size: 12px;
    }
    @media (max-width: 400px) {
      font-size: 11px;
    }
    }
}
.filter {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  display: block;
  justify-content: space-between;
  label {
    margin-left: 15px;
    @media (max-width: 700px) {
      margin-left: 10px;
      font-size: 14px;
    }
    @media (max-width: 500px) {
      font-size: 11px;
    }
    @media (max-width: 400px) {
      margin-left: 5px;
      font-size: 10px;
    }
  }
  input {
    height: 24px;
    margin-top: 10px;
    background-color: white;
    width: 95%;
    position: relative;
    box-sizing: border-box;
    padding: 10px ;
    border: 1px solid #8b8b8b;
    border-radius: 2px;
    font-size: 12px;
    @media (max-width: 700px) {
      font-size: 10px;
    }
    @media (max-width: 500px) {
      font-size: 9px;
    }
    @media (max-width: 420px) {
      font-size: 9px;
      width: 200%;
    }
    @media (max-width: 400px) {
      font-size: 8px;
    }
  }
  input:focus {
    border: none;
    border-bottom: 2px solid #3131a1;
    outline: none;
    label {
      border: 1px solid red;
    }
  }
  .validation-message {
    display: block;
    font-size: 14px;
    color: red;
    @media (max-width: 700px) {
      font-size: 13px;
    }
    @media (max-width: 400px) {
      font-size: 12px;
    }
  }
}
.input-height {
  margin-top: 20px;
  height: 64px;
  width: 100%;
}
.input-height-2 {
  padding-top: 20px;
  margin-top: 20px;
  height: 64px;
  width: 100%;
  @media (max-width: 860px) {
    padding-top: 0;
  }
}
.validation-message {
  display: block;
  font-size: 14px;
  color: red;
  @media (max-width: 700px) {
    font-size: 13px;
  }
  @media (max-width: 400px) {
    font-size: 12px;
  }
}
.label {
  margin-left: 15px;
}
.btn-text-center {
  display: block;
  height: 50px;
  margin-top: 50px;
  text-align: center;
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 97.5%;
}