

.edit-partner {
  max-width: 640px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.edit-partner h1 {
  font-size: 23px;
  padding: 10px ;
  text-align: left;
}

.edit-partner-inside {
  padding: 30px 50px;
  background-color: #F0F0F0;
  border-radius: 4px;
  box-shadow: 15px 15px 25px 0 rgba(#32324B, 0.4);
}



label {
  margin-left: 15px ;
}



/* BUTTON */

.btn-center {
  text-align: center;
}


/* ИТОГ КНОПКИ */

.edit-wrong{
  color: red;
  margin: 0;
  padding: 0;
  font-size: 14px;
  text-align: center;
  font-weight: lighter;
}

.edit-correct {
  color: #01c901;
  margin: 0;
  padding: 0;
  font-size: 14px;
  text-align: center;
  font-weight: lighter;
}









